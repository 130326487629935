import { Provider as ReduxProvider } from "react-redux";
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Routes from "./Routes";
import { QueryProvider } from "./components";

import "./i18n";
import store from "./store";

import AppProvider from "./providers/AppProvider.jsx";
import StoreSeoProvider from "./providers/StoreSeoProvider";

export default function App() {
  const pages = import.meta.glob("./pages/**/!(*.test.[jt]sx)*.([jt]sx)");

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <QueryProvider>
            <ReduxProvider store={store}>
              <StoreSeoProvider>
                <Outlet />
              </StoreSeoProvider>
            </ReduxProvider>
          </QueryProvider>
        }
      >
        {Routes({ pages })}
      </Route>
    )
  );

  return (
    <AppProvider>
      <RouterProvider router={router}></RouterProvider>
    </AppProvider>
  );
}
