import React from "react";
import { AppProvider as PolarisAppProvider, Frame } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import ReactPolarisLink from "../components/common/ReactPolarisLink";

// import "@shopify/polaris/build/esm/styles.css";

export default function AppProvider({ children, ...polarisProps }) {
  return (
    <>
      <PolarisAppProvider
        i18n={translations}
        linkComponent={ReactPolarisLink}
        {...polarisProps}
      >
        <Frame
          logo={{
            contextualSaveBarSource: "/img/svg/storeseo-logo-white.svg",
          }}
        >
          {children}
        </Frame>
      </PolarisAppProvider>
    </>
  );
}
