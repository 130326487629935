import { useTranslation } from "react-i18next";
import { EmptyState, Page, Text } from "@shopify/polaris";
import { rootFlexStyle } from "../styles/common.js";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div style={rootFlexStyle}>
      <div
        style={{
          flex: 1,
          display: "flex",
          width: "100%",
          minWidth: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <Page>
            <EmptyState
              heading={t("There's no page at this address")}
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/f9352e9573adbafe.svg"
              action={{
                content: t("Go to Dashboard"),
                onAction() {
                  navigate("/");
                },
              }}
            >
              <Text as={"p"}>{t("Check the URL and try again, or use the search bar to find what you need.")}</Text>
            </EmptyState>
          </Page>
        </div>
      </div>
    </div>
  );
}
