import ReactDOM from "react-dom";

import { onCLS, onLCP } from "web-vitals";
onLCP((metric) => console.log("LCP: ", { ...metric }), { reportAllChanges: true });
onCLS((metric) => console.log("CLS: ", { ...metric }), { reportAllChanges: true });

import App from "./App";

export default function renderApp() {
  ReactDOM.render(<App />, document.getElementById("app"));
}

renderApp();
