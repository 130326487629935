import React from "react";
import { Link } from "react-router-dom";

const ReactPolarisLink = React.forwardRef((props, ref) => (
  <Link
    ref={ref}
    to={props.url ?? props.to}
    {...props}
  />
));

export default ReactPolarisLink;
